import { Popover } from "@radix-ui/react-popover";
import * as Lucide from "lucide-react";
import { useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router";
import type { GetEpisodeShape } from "~/.server/episode";
import { Header } from "~/components/header";
import { Calendar } from "~/components/ui/calendar";
import { PopoverContent, PopoverTrigger } from "~/components/ui/popover";
import { formatTimeOfDay } from "~/lib/utils";
import { episodeShortName, nextEpisodePublishDay } from "~/model/episode";
import { requireUser } from "~/pages/with-user";
import type { loader } from "./.server/loader";
import { DebugMenu } from "./debug";
import { Player } from "./player";

// PAGE HEADER

function HeaderLink({ children, to }: { children: React.ReactNode; to: string }) {
  return (
    <Link
      to={to}
      className="underline-offset-2 underline decoration-header-foreground/50 hover:decoration-header-foreground hover:text-header-foreground focus-visible:decoration-header-foreground focusable focus-visible:text-header-foreground"
    >
      {children}
    </Link>
  );
}

function NextEpisode() {
  const { sources, nextEpisode } = useLoaderData<typeof loader>();
  const user = requireUser();

  if (!sources) return null;
  const sourcesText = sources === 1 ? "one source" : `${sources} sources`;

  return (
    <div className="flex items-center gap-1 text-header-foreground/70 border-r border-header-foreground/50 text-sm pr-5 mr-2">
      {nextEpisode ? (
        nextEpisode.catchup ? (
          <span>
            Summary creation is paused until{" "}
            <HeaderLink to="/settings">{nextEpisodePublishDay(nextEpisode, user, true)}</HeaderLink>
          </span>
        ) : (
          <span>
            Next summary will be created{" "}
            <HeaderLink to="/settings">
              {nextEpisodePublishDay(nextEpisode, user)} at {formatTimeOfDay(user)}
            </HeaderLink>{" "}
            from <HeaderLink to="/settings/sources">{sourcesText}</HeaderLink>
          </span>
        )
      ) : (
        <span>
          Summary creation is <HeaderLink to="/settings">paused</HeaderLink>
        </span>
      )}
    </div>
  );
}

export function PageHeader() {
  return (
    <Header>
      <NextEpisode />
    </Header>
  );
}

// EPISODE HEADER

export function EpisodeName({ episode }: { episode: GetEpisodeShape }) {
  const { allEpisodes, indexEpisodeKey } = useLoaderData<typeof loader>();
  const user = requireUser();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function dateToEpisodeKey(date: Date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  }

  function goToEpisode(date: Date | undefined) {
    if (date) {
      const episodeKey = dateToEpisodeKey(date);
      setOpen(false);
      navigate(`/${episodeKey === indexEpisodeKey ? "" : episodeKey}`);
    }
  }

  function disabledDate(date: Date) {
    const episodeKey = dateToEpisodeKey(date);
    return !allEpisodes.some((episode) => episode.episodeKey === episodeKey);
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          className="font-serif font-semibold text-xl uppercase cursor-pointer hover:bg-foreground/5 mx-1 px-1 py-0.5 rounded-md group flex flex-row items-center focusable"
        >
          {episodeShortName(episode, user)}
          <Lucide.ChevronDown className="ml-1 size-4 group-hover:opacity-50  group-focus-visible:opacity-50 opacity-0" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 flex flex-col items-center" align="start">
        <Calendar
          id="episodeDate"
          mode="single"
          selected={episode.untilTime}
          onSelect={goToEpisode}
          disabled={disabledDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export function EpisodeHeader({ episode }: { episode: GetEpisodeShape }) {
  return (
    <div className="text-foreground flex flex-row items-center border-b border-divider mt-6 mx-6 py-1 sticky z-20 top-0 bg-background h-12">
      <EpisodeName episode={episode} />
      <div className="grow" />
      {episode.speechStorageUrl && <Player key={episode.episodeKey} episode={episode} />}
      <DebugMenu episode={episode} />
    </div>
  );
}
