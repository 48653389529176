import { useEffect, useRef, useState } from "react";
import type { GetEpisodeShape } from "~/.server/episode";
import { cn } from "~/lib/utils";

type Action = "play-disabled" | "play" | "pause";

function ProgressBar({
  duration,
  currentTime,
  onSeek,
}: { duration: number; currentTime: number; onSeek: (time: number) => void }) {
  const width = (currentTime / duration) * 100;
  const containerRef = useRef<HTMLDivElement>(null);

  function updateSeekPosition(event: PointerEvent) {
    const container = containerRef.current;
    if (!container) return;
    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const percentage = x / rect.width;
    const seekTime = duration * Math.max(0, Math.min(1, percentage));
    onSeek(seekTime);
  }

  function startSeek(event: React.PointerEvent<HTMLDivElement>) {
    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const percentage = x / rect.width;
    const seekTime = duration * Math.max(0, Math.min(1, percentage));
    onSeek(seekTime);

    const abortController = new AbortController();
    const signal = abortController.signal;
    window.addEventListener("pointermove", updateSeekPosition, { signal });
    window.addEventListener("pointerup", () => abortController.abort(), { signal });
  }

  return (
    <div
      className="absolute -bottom-[1.5px] left-0 w-full flex flex-row items-center group cursor-pointer"
      ref={containerRef}
      onPointerDown={(event) => startSeek(event)}
    >
      <div className="h-[2px] bg-primary rounded-l-full rounded-r-full relative" style={{ width: `${width}%` }}>
        <div className="size-[16px] p-[5px] absolute -top-[7px] -right-[8px]">
          <div className="size-[6px] rounded-full bg-primary" />
        </div>
      </div>
    </div>
  );
}

function PlayIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor">
      <path d="M7.783 5.0878C7.70793 5.03626 7.62021 5.0062 7.5293 5.00086C7.4384 4.99552 7.34776 5.01511 7.26717 5.05752C7.18659 5.09992 7.11911 5.16353 7.07203 5.24147C7.02494 5.31941 7.00004 5.40874 7 5.4998V16.4998C7.00017 16.5909 7.02522 16.6802 7.07245 16.7581C7.11968 16.836 7.1873 16.8995 7.268 16.9418C7.33936 16.98 7.41906 16.9999 7.5 16.9998C7.60106 16.9997 7.69973 16.9691 7.783 16.9118L15.783 11.4118C15.8498 11.3658 15.9045 11.3043 15.9422 11.2326C15.98 11.1608 15.9997 11.0809 15.9997 10.9998C15.9997 10.9187 15.98 10.8388 15.9422 10.767C15.9045 10.6953 15.8498 10.6337 15.783 10.5878L7.783 5.0878Z" />
    </svg>
  );
}

function PauseIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor">
      <path d="M15.5 5H12.5C12.2239 5 12 5.22386 12 5.5V16.5C12 16.7761 12.2239 17 12.5 17H15.5C15.7761 17 16 16.7761 16 16.5V5.5C16 5.22386 15.7761 5 15.5 5Z" />
      <path d="M9.5 5H6.5C6.22386 5 6 5.22386 6 5.5V16.5C6 16.7761 6.22386 17 6.5 17H9.5C9.77614 17 10 16.7761 10 16.5V5.5C10 5.22386 9.77614 5 9.5 5Z" />
    </svg>
  );
}

export function Player({ episode }: { episode: GetEpisodeShape }) {
  const player = useRef<HTMLAudioElement>(null);
  const [canPlay, setCanPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [currentTime, setCurrentTime] = useState<number | undefined>(undefined);

  const src = `/${episode.episodeKey}.mp3`;

  const remainingTime = duration ? (currentTime ? duration - currentTime : duration) : undefined;

  const actionType: Action = canPlay ? (isPlaying ? "pause" : "play") : "play-disabled";
  function action() {
    if (actionType === "play") {
      player.current?.play();
    } else if (actionType === "pause") {
      player.current?.pause();
    }
  }

  useEffect(() => {
    const audio = player.current;
    if (!audio) return;
    audio.src = src;
    const controller = new AbortController();
    const signal = controller.signal;
    audio.addEventListener("canplay", () => setCanPlay(true), { signal });
    audio.addEventListener("play", () => setIsPlaying(true), { signal });
    audio.addEventListener("pause", () => setIsPlaying(false), { signal });
    audio.addEventListener("durationchange", () => setDuration(audio.duration), { signal });
    audio.addEventListener("timeupdate", () => setCurrentTime(audio.currentTime), { signal });
    audio.addEventListener(
      "ended",
      () => {
        setCurrentTime(undefined);
        setIsPlaying(false);
      },
      { signal },
    );
    return () => controller.abort();
  }, [src]);

  function seek(time: number) {
    if (player.current && duration) {
      player.current.currentTime = Math.max(0, Math.min(time, duration - 1));
    }
  }

  return (
    <>
      <div className="text-sm text-foreground/50 pl-1 flex flex-row items-center">
        <div className="h-5 mr-3" />
        {remainingTime != null ? (
          <span className="text-center flex flex-row items-center justify-center min-w-10">
            <span>{`${Math.floor(remainingTime / 60)}`}</span>
            <span className="min-w-6 text-left">{`:${String(Math.floor(remainingTime % 60)).padStart(2, "0")}`}</span>
          </span>
        ) : null}
        <audio ref={player} src={src} preload="auto" />
        <button
          type="button"
          className="ml-1 rounded-full size-8 text-foreground p-0 group flex items-center justify-center cursor-pointer mr-2 focusable"
          onClick={action}
        >
          <span
            className={cn(
              "size-5.5 rounded-full bg-foreground/10 group-hover:bg-foreground/20 text-foreground/70 group-hover:text-foreground",
              actionType === "play-disabled" ? "text-foreground/20" : "",
            )}
          >
            {actionType === "pause" ? <PauseIcon /> : <PlayIcon />}
          </span>
        </button>
      </div>
      {duration && currentTime ? <ProgressBar onSeek={seek} duration={duration} currentTime={currentTime} /> : null}
    </>
  );
}
