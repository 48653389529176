import type { Episode, Prisma } from "@prisma/client";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import * as Lucide from "lucide-react";
import { type ActionFunctionArgs, Link, useFetcher } from "react-router";
import { db } from "~/.server/db";
import { getRequestEpisode } from "~/.server/episode";
import { publishPendingEpisodes } from "~/.server/pub/publish";
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "~/components/ui/dropdown-menu";
import { requireUser } from "~/pages/with-user";

export const action = async (args: ActionFunctionArgs) => {
  const episode = await getRequestEpisode(args);
  const formData = await args.request.formData();
  const action = formData.get("action");

  const episodeData: Prisma.EpisodeUpdateInput = {
    scheduledAt: new Date(),
    status: "queued",
  };

  if (action === "regenerate" || action === "regenerate-summary" || action === "regenerate-speech") {
    episodeData.speechStorageUrl = null;
  }

  await db.episode.update({
    where: {
      userId_episodeKey: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
    },
    data: episodeData,
  });

  if (action === "regenerate") {
    await db.sourceSummary.deleteMany({
      where: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
    });
  }

  if (action === "regenerate-summary") {
    await db.sourceSummary.updateMany({
      where: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
        status: "summary",
      },
      data: {
        summaries: [],
        status: "content",
      },
    });
  }

  await publishPendingEpisodes();

  return null;
};

export function DebugMenu({ episode }: { episode: Episode }) {
  const fetcher = useFetcher();
  const user = requireUser();
  if (!user.devMode) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          type="button"
          className="rounded-full size-8 text-foreground mr-2 p-0 group  flex items-center justify-center cursor-pointer focusable"
        >
          <Lucide.Wrench className="size-5.5 p-1 rounded-full bg-foreground/10 group-hover:bg-foreground/20 stroke-foreground/70 group-hover:stroke-foreground" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent asChild align="end">
        <fetcher.Form method="post" action={`/${episode.episodeKey}/debug`} className="flex flex-col">
          <DropdownMenuItem asChild>
            <Link to={`/${episode.episodeKey}/raw-summaries`} target="_blank">
              <Lucide.Inspect />
              <span>Show raw summaries</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate" type="submit">
              <Lucide.RefreshCcw />
              <span>Regenerate all</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-summary" type="submit">
              <Lucide.Merge />
              <span>Regenerate summary</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-speech" type="submit">
              <Lucide.Speech />
              <span>Regenerate speech</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-email" type="submit">
              <Lucide.Mail />
              <span>Regenerate email</span>
            </button>
          </DropdownMenuItem>
        </fetcher.Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
