import type { SourceType } from "@prisma/client";
import { useEffect } from "react";
import { Link, useLoaderData, useRevalidator, type MetaFunction } from "react-router";
import type { GetEpisodeShape, GetEpisodeSummaryShape } from "~/.server/episode";
import { episodeShortName } from "~/model/episode";
import { sourceSlug } from "~/model/source";
import { SummaryPeriod } from "~/pages/source/header";
import { SourceOptions } from "~/pages/source/options";
import { SourceSummarySection } from "~/pages/source/section";
import { userFromMatches } from "~/pages/with-user";
import { getSourceMeta } from "~/sources/config";
import type { loader } from "./.server/loader";
import { EpisodeFooter } from "./footer";
import { EpisodeHeader, PageHeader } from "./header";

export { loader } from "./.server/loader";

function Preparing() {
  return (
    <div className="mx-6 px-2 py-6 border-b border-divider">
      <h4 className="font-bold mb-2">Summary is being generated...</h4>
      <p>This may take a few minutes. We'll email you once it's ready.</p>
    </div>
  );
}

function NoContent() {
  return <div className="mx-6 px-2 py-6 border-b border-divider">Sorry, there was nothing to summarize.</div>;
}

export function SourceHeader({ summary, showFullName }: { summary: GetEpisodeSummaryShape; showFullName: boolean }) {
  const { isIndex } = useLoaderData<typeof loader>();
  const meta = getSourceMeta(summary.source.sourceType);
  showFullName = showFullName || (meta.alwaysShowSourceName ?? false);
  const url = isIndex ? `${sourceSlug(summary.source)}` : `${sourceSlug(summary.source)}/${summary.episodeKey}`;
  return (
    <h2 className="px-1 py-0.5 border-b border-divider sticky top-12 bg-background z-10 flex flex-row items-center h-12">
      <Link
        to={url}
        className="pr-2 py-0.5 rounded-md font-serif font-semibold text-xl text-primary uppercase  hover:bg-foreground/5"
      >
        <span className="inline-flex items-center justify-center text-primary w-8">
          <img src={meta.iconMono} alt={meta.name} />
        </span>
        <span>{showFullName ? summary.source.sourceName : meta.name}</span>
      </Link>
      <span className="grow" />
      <SummaryPeriod summary={summary} />
      <SourceOptions source={summary.source} />
    </h2>
  );
}

export function SourceSummary({
  summary,
  showFullName,
}: {
  summary: GetEpisodeSummaryShape;
  showFullName: boolean;
}) {
  const viewMode = summary.source.config.viewMode ?? "list";
  return (
    <div className="mt-6 mx-6 text-[15px]">
      <SourceHeader summary={summary} showFullName={showFullName} />
      {summary.summaries.map((summarySection, index) => (
        <SourceSummarySection key={index} summarySection={summarySection} viewMode={viewMode} />
      ))}
    </div>
  );
}

function EpisodeContent({ episode }: { episode: GetEpisodeShape }) {
  const { episodeKey, status } = episode;
  const revalidator = useRevalidator();
  const trackUpdates = status !== "ok";
  const preparing = status === "running" || status === "queued";
  const hasContent = episode.summaries.some((summary) => summary.summaries.length > 0);

  function isMultipleSources(sourceType: SourceType) {
    return episode.summaries.filter((summary) => summary.source.sourceType === sourceType).length > 1;
  }

  useEffect(() => {
    if (trackUpdates) {
      const source = new EventSource(`/${episodeKey}/updates`);
      source.onmessage = (_event) => {
        revalidator.revalidate();
      };
      return () => source.close();
    }
  }, [episodeKey, trackUpdates]);

  return (
    <div className="w-full">
      <div className="content-w flex flex-col">
        <EpisodeHeader episode={episode} />
        {preparing ? (
          <Preparing />
        ) : hasContent ? (
          episode.summaries.map((summary) =>
            summary.summaries.length > 0 ? (
              <SourceSummary
                key={summary.source.sourceId}
                summary={summary}
                showFullName={isMultipleSources(summary.source.sourceType)}
              />
            ) : null,
          )
        ) : (
          <NoContent />
        )}
        <EpisodeFooter />
      </div>
    </div>
  );
}

/*
function ZeroLimit() {
  // TODO: make this nicer
  return (
    <div className="flex-1 p-10">
      <p>
        <Link
          to="/settings"
          className="underline decoration-foreground/20 hover:decoration-foreground focusable"
        >
          Summary retention
        </Link>{" "}
        is disabled. Next summary will be delivered to your inbox only.
      </p>
    </div>
  );
}
  */

export default function EpisodePage() {
  const { episode } = useLoaderData<typeof loader>();

  return (
    <div className="flex flex-col min-h-full">
      <PageHeader />
      {episode ? <EpisodeContent episode={episode} /> : null}
    </div>
  );
}

export const meta: MetaFunction = ({ data, matches }) => {
  const { episode } = data as ReturnType<typeof useLoaderData<typeof loader>>;
  if (!episode) return [{ title: "Sumcast" }];
  const user = userFromMatches(matches);
  return [{ title: `${episodeShortName(episode, user)} · Sumcast` }];
};
